// vue components
export * as Slider from './vue-components/slider/src/main'
export * as AirlinesAiportsList from './vue-components/airlines-airports/src/main.ts'
export * as ProblemFlightsList from './vue-components/problem-flights/src/main.ts'

// set libs
export { default as setVendors } from './vendor/setVendors'

// vanilla components
export { default as chatHelper } from './helpers/chatHelper'
export { default as collapsibleHelper } from './helpers/collapsibleHelper'
export { default as copyToClipboard } from './helpers/copyToClipboard'
export { default as faqHelper } from './helpers/faqHelper'
export { default as foldOutNav } from './helpers/foldOutNavHelper'
export { default as labelHelper } from './helpers/labelHelper'
export { default as lazyLoading } from './helpers/lazyLoading'
export { default as openNavigation } from './helpers/openNavigation'
export { default as problemFlightHelper } from './helpers/problemFlightHelper'
export { default as simpleDropDown } from './helpers/simpleDropDown.js'
// export { default as stickyCTAButtonHelper } from './helpers/stickyCTAButtonHelper.js'

export { default as ABTest } from './modules/ABTest.js'
export { default as OriginalReferrer } from './modules/OriginalReferrer.js'
export * as errorHandler from './modules/errorHandler.js'
export { default as formManager } from './modules/formManager.js'
export { default as modalManager } from './modules/modalManager.js'

// remove attributes with fields
document.currentScript.removeAttribute('generalFields')
document.currentScript.removeAttribute('chatFields')

